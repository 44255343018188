import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../pages/Main/index.vue')
  },
  {
    path: '/catalog',
    name: "Catalog",
    component: () => import('../pages/Catalog/index.vue')
  },
  {
    path: '/catalog/:id/products',
    name: "Products",
    component: () => import('../pages/Products/index.vue')
  },
  {
    path: '/catalog/:id/products/:product_id',
    name: "Product",
    component: () => import('../pages/Products/[id].vue')
  },
  {
    path: '/search/:name',
    name: "Search",
    component: () => import('../pages/Search/index.vue')
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import('../pages/Favorites/index.vue')
  },
  {
    path: '/blog',
    name: "Blog",
    component: () => import('../pages/Blog/index.vue')
  },
  {
    path: '/blog/:id',
    name: "Blog id",
    component: () => import('../pages/Blog/id.vue')
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('../pages/Clients/index.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../pages/Profile/index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/basket',
    name: 'Basket',
    component: () => import('../pages/Basket/index.vue'),
    // meta: { requiresAuth: true }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../pages/Order/index.vue'),
  },
  {
    path: '/warehouse_order/:id',
    name: 'Wr Order',
    component: () => import('../pages/Order/warehouseOrder.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../pages/About/index.vue'),
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('../pages/Partners/index.vue'),
  },
  {
    path: '/school',
    name: 'School',
    component: () => import('../pages/School/index.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../pages/Auth/index.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../pages/404/index.vue')
  },
  {
    path: '/payment/success',
    name: 'SuccessPayment',
    component: () => import('../pages/PaymentsStatus/success.vue'),
  },
  {
    path: '/payment/error',
    name: 'ErrorPayment',
    component: () => import('../pages/PaymentsStatus/error.vue'),
  },
  {
    path: '/order/success',
    name: 'SuccessPayment order',
    component: () => import('../pages/Order/success.vue'),
  },
  {
    path: '/order/error',
    name: 'ErrorPayment error',
    component: () => import('../pages/Order/error.vue'),
  },
]

const token = localStorage.getItem("authToken")

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({ path: '/' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
