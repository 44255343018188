<template>
  <p class="gray-900 text-2xl semibold">Мой склад</p>
  <main-loader v-if="isLoading" class="main__loader-mini" />
  <div v-else class="my-orders my-warehouse">
    <div class="my-orders__cards" v-if="orders">
      <div class="my-orders__card" v-for="(item, i) in orders" :key="i">
        <div class="my-orders__card-info">
          <div class="img">
            <img
              :src="item.product.photo_uris[0]"
              alt=""
              v-if="item.product.photo_uris && item.product.photo_uris.length > 0"
              />
            <img v-else src="@/assets/images/empty-bg.png" alt="" />
          </div>

          <p class="product-title">
            {{ item.product?.title }} 
          </p>

          <!-- <div>
            <label> {{ $t('orderNumber') }} </label>
            <p v-if="item.id">
              {{ item.id }}
            </p>
          </div> -->

          <!-- <div>
            <label> {{ $t('orderDate') }} </label>
            <p v-if="item.created_at">
              {{ formatDate(item.created_at) }}
            </p>
          </div> -->

          <div class="sb">
            <label> Количество: </label>
            <p v-if="item.quantity">{{ item.quantity }} шт.</p>
          </div>

          <div class="sb">
            <label> Итого: </label>
            <p v-if="item.product?.price">{{ item.product?.price }} ₸</p>
          </div>

          <div class="actions" @click="openOrderModal(item.product.id)">
            <button class="button-border">Открыть полку</button>
          </div>
        </div>

        <div class="my-orders__card-image" v-if="item.products">
          <div class="scroll">
            <div v-for="(product, n) in item.products" :key="n">
              <img
                v-if="
                  product &&
                  product.product &&
                  product.product.photo_uris &&
                  product.product.photo_uris.length > 0
                "
                :src="product.product.photo_uris[0]"
                alt=""
              />
              <img v-else src="@/assets/images/empty-bg.png" alt="" />
            </div>
          </div>
        </div>

        <div class="actions">
          <button class="button-border" @click="openOrderModal(item.id)">
            {{ $t('viewOrder') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <warehouse-info-modal  v-if="showOrderModal"
    :orderId="selectedOrderId"
    @closeModal="showOrderModal = false" />
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import WarehouseInfoModal from "../Modals/WarehouseInfoModal.vue";

const store = useStore();

const isLoading = ref(true);

const orders = computed(() => store.state.profile.my_warehouse);

const showOrderModal = ref(false);

const selectedOrderId = ref(null);

const openOrderModal = (orderId) => {
  selectedOrderId.value = orderId;
  showOrderModal.value = true;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  return new Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};

onMounted(async () => {
  try {
    await Promise.all([store.dispatch("profile/MyWarehouse")]);
  } catch (error) {
    console.log("Error loading data:", error);
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss">
.my-warehouse{
  .my-orders__cards{
    flex-direction: unset;
    .my-orders__card{
      max-width: calc(33.3333% - 16px);
      .actions{
        width: 100%;
        button{
          width: 100%;
        }
      }
      .my-orders__card-info{
        flex-direction: column;
        margin-bottom: 0;
        gap: 16px;
        .sb{
          flex-direction: row;
          gap: 6px;
        }
      }
      .product-title{
        font-size: 18px;
        line-height: normal;
        color: #344054;
        font-weight: 600;
      }
      .img{
        max-width: 100% !important;
        img{
          width: 100%;
          max-height: 250px;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }
  }
}

@media (min-width: 861px) and (max-width: 1190px){
.my-warehouse{
  .my-orders__cards{
    flex-direction: unset;
    .my-orders__card{
      max-width: calc(50% - 12px);
      .actions{
        width: 100%;
        button{
          width: 100%;
        }
      }
      .my-orders__card-info{
        flex-direction: column;
        margin-bottom: 0;
        gap: 16px;
        .sb{
          flex-direction: row;
          gap: 6px;
        }
      }
      .product-title{
        font-size: 18px;
        line-height: normal;
        color: #344054;
        font-weight: 600;
      }
      .img{
        width: 100%;
        img{
          width: 100%;
          max-height: 250px;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }
  }
}
}

@media (max-width: 860px){
  .my-warehouse{
  .my-orders__cards{
    flex-direction: unset;
    .my-orders__card{
      max-width: 100%;
      .actions{
        width: 100%;
        button{
          width: 100%;
        }
      }
      .my-orders__card-info{
        flex-direction: column;
        margin-bottom: 0;
        gap: 16px;
        .sb{
          flex-direction: row;
          gap: 6px;
        }
      }
      .product-title{
        font-size: 18px;
        line-height: normal;
        color: #344054;
        font-weight: 600;
      }
      .img{
        width: 100%;
        img{
          width: 100%;
          max-height: 250px;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }
  }
}
}
</style>
