<template>
  <div class="drawer drawer-right basket" :class="{ 'show-modal': animateModal }">
    <div class="drawer__inner">
      <main-loader v-if="isLoading" class="main__loader-mini" />
      <div v-else class="drawer__wrap">
        <div class="basket__header">
          <div class="basket__header-item">
          </div>
          <span class="drawer__close">
            <img @click="close()" src="@/assets/images/x-close.svg" alt="" />
          </span>
        </div>
        <div class="basket__content order-info" v-if="order">
          <p class="text-xl bold gray-900" v-if="order.id">
            Полка №{{ order.id }}
          </p>
          <div class="order-info__up">
            <div>
              <span class="gray-500 text-md regular">Дата заказа</span>
              <p class="gray-900 text-md medium">{{ formatDate(order.created_at) }}</p>
            </div>
            <!-- <div>
              <span class="gray-500 text-md regular">Итого</span>
              <p class="gray-900 text-md medium">{{ order.product?.price }} ₸</p>
            </div> -->
            <div v-if="order.payment_method">
              <span class="gray-500 text-md regular">Способ оплаты</span>
              <p class="gray-900 text-md medium">{{ order.payment_method?.title }}</p>
            </div>
          </div>
          <div class="order-info__products" v-if="order.product">
            <div class="order-info__item">
              <div class="img">
                <img :src="order.product.photo_uris[0]" v-if="order.product.photo_uris[0]" alt="">
                <img src="../../../assets/images/empty-bg.png" v-else alt="">
              </div>
              <div class="text">
                <p class="text_name">{{ order.product.title }}</p>
                <p class="text_number">Кол-во: {{ order.quantity }}</p>
                <div class="text_price" v-if="order.product.discount_price">
                  <p>{{ formatNumber(order.product.discount_price) }} ₸</p>
                  <span>{{ formatNumber(order.product.price) }} ₸</span>
                </div>
                <div class="text_price" v-if="!order.product.discount_price">
                  <p>{{ formatNumber(order.product.price) }} ₸</p>
                </div>
              </div>
            </div>
          </div>
          <div class="order-info__prices" v-if="order.product?.price">
            <div class="order-info__prices_item">
              <p>Итого</p>
              <span>{{ order.product?.price }}  ₸</span>
            </div>
          </div>
          <div class="warehouse-btn">
            <button class="button" @click="orderRedirect()">Оформить заказ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { formatNumber } from '@/helpers/helpers';

const props = defineProps({
  orderId: {
    type: Number,
    required: true
  }
});

const router = useRouter();

const emits = defineEmits(["closeModal"]);

const store = useStore();

const isLoading = ref(true);

const animateModal = ref(false);

const order = computed(() => store.state.order.warehouse_details);

const fetchOrderDetails = async (orderId) => {
  try {
    const response = await store.dispatch("order/getWarehouseDetails", orderId);

    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
  }
};

const close = () => {
  animateModal.value = false;

  setTimeout(() => {
    emits("closeModal");
  }, 200);
};

const orderRedirect = () => {
  router.push('/warehouse_order/' + order.value.product.id)
}

const formatDate = (dateString) => {
  const date = new Date(dateString);

  return new Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};

onMounted(async() => {
  setTimeout(() => {
    animateModal.value = true;
  }, 80);

  await fetchOrderDetails(props.orderId);
});
</script>

<style lang="scss">
.warehouse-btn{
  margin-top: 16px;
  button{
    font-size: 16px;
    width: 100%;
    height: 52px;
    line-height: normal;
  }
}
</style>
